/*
 * Constants
 ***************************/
const mobileBreakpoint = 767;

/*
 * Globals
 ***************************/
 /* Libraries */
    var $ = jQuery.noConflict();
    const controller = new ScrollMagic.Controller({

    });
  /* Window */
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var windowScrollHeight = document.querySelector('body').scrollHeight;
  /* Breakpoints */
    var isMobile = ( windowWidth <= mobileBreakpoint );
  /* Mobile Navigation */
    var isMenuOpen = false;

const handleKeypressADA = function() {
  const keycode = (event.keyCode ? event.keyCode : event.which);
  if (keycode == '13') return;
  $(this).trigger('click');
};

$(window).on('load', function() {
  windowWidth = $(window).width();
  windowHeight = $(window).height();
  windowScrollHeight = document.querySelector('body').scrollHeight;
});

$(document).ready(function(){
  /* Menu */
  $('.site-header__mobiletoggle').on('click', function(){
    isMenuOpen = !isMenuOpen;
    console.log(isMenuOpen);
    $(this).attr('aria-expanded', isMenuOpen);
    $(this).toggleClass('active');
    $('.site-header').toggleClass('active');
    if ( isMenuOpen ) {
      $('.site-header').css('height', $('.site-header').get(0).scrollHeight);
    } else {
      $('.site-header').css('height', '');
    }
  }).on('keypress', handleKeypressADA);

  $('.site-header__nav .menu-item-has-children > a').on('click', function(e){
    e.preventDefault();
    const sub = $(this).next('.sub-menu');
    sub.toggleClass('active');
    sub.parent().toggleClass('active');

    if ( sub.hasClass('active') ) {
      sub.css('height', sub.get(0).scrollHeight);
      if ( isMobile ) {
        $('.site-header').css('height', $('.site-header').height() + sub.get(0).scrollHeight);
      }
    } else {
      sub.css('height', '');
      if ( isMobile ) {
        $('.site-header').css('height', $('.site-header').height() - sub.get(0).scrollHeight);
      }
    }
  });
  /* END MENU SCRIPTS */

  /* Generic */
  if ( $('.smooth-scroller').length ) { // internal links, smooth scrolling
    $('.smooth-scroller').on('click', function(e){
      e.preventDefault();
      let el = $( $(this).attr('href') );
      console.log(el.prev());
      if ( el.prev().hasClass('wp-block-image') ) {
        el = $(el).prev();
      }
      let target = el.offset().top - 120;
      let dist = Math.abs($(window).scrollTop() - target);

      $([document.documentElement, document.body]).animate({
        scrollTop: target
      }, Math.min(1200, ((dist / 1200) * 1000)));
    }).on('keypress', handleKeypressADA);
  }

  if ( $('.page-header__background, .wp-block-image.alignfull').length ) {
    $('.page-header__background, .wp-block-image.alignfull').each(function(){
      const par = new simpleParallax( $(this).find('img').get(0), {
        scale: 1.2,
        orientation: 'up'
      });
    });
  }

  if ( $('h2').length ) {
    $('h2').each(function(){
      if ( $(this).find('img') ) {
        $(this).addClass('has-image');
        $(this).find('img').wrap('<figure></figure>');
      }
    });
  }
  /* END GENERIC SCRIPTS */

  /* STATISTIC BLOCK SCENES */
  if ( $('.statistic-block').length ) {
    $('.statistic-block').each(function(){
      let delay = 0;

      if ( $(this).closest('.wp-block-columns').length ) {
        const index = $(this).closest('.wp-block-columns').find('.statistic-block').index($(this));
        // console.log(index);
        delay = 200 * index;
        $(window).on('resize', function(){
          if ( $(window).width() < 768 ) {
            delay = 0;
          } else {
            delay = 200 * index;
          }
        });
      }



      const options = {
        duration: 1.2,
        decimalPlaces: $(this).data('places'),
        separator: $(this).data('separator'),
        decimal: $(this).data('decseparator')
      };

      if ( $(this).data('suffix') ) {
        options.suffix = $(this).data('suffix');
      }

      if ( $(this).data('prefix') ) {
        options.prefix = $(this).data('prefix');
      }

      const thisRef = $(this);

      const counter = new CountUp($(this).attr('id') + '-figure', $(this).data('num'), options);
      const scene = new ScrollMagic.Scene({
        triggerElement: '#' + $(this).attr('id'),
        triggerHook: 1,
        duration: '300%',
        offset: 90
      })
      .on('enter leave', function(){
        counter.reset();
        setTimeout(function(){
          counter.start();
          thisRef.toggleClass('active');
        }, delay);
      })
      .addTo(controller);
    });
  }
  /* END STATISTIC BLOCK */

  /* TOC BLOCK */
  if ( $('.wp-block-cmyk-toc__item a').length ) {
    $('.wp-block-cmyk-toc__item a').each(function(){
      const target = $(this).attr('href');
      const thisRef = $(this);

      let el = $( target );
      if ( el.prev().hasClass('wp-block-image') ) {
        el = el.prev();
      }

      let sectionHeightRef = get_toc_section_height(thisRef);
      $(window).on('resize', function(){
        sectionHeightRef = get_toc_section_height(thisRef);
      });

      const scene = new ScrollMagic.Scene({
        triggerElement: el.get(0),
        triggerHook: 0,
        offset: -120,
        duration: function() { return sectionHeightRef; }
      })
      .on('enter leave', function () {
        thisRef.toggleClass('active');
      })
      .addTo(controller);
    });
  }
  /* END TOC BLOCK */
}); // document ready end

/*
 * Window Resize
 ***************************/
$(window).resize(function(){
  windowWidth = $(window).width();
  windowHeight = $(window).height();
  windowScrollHeight = document.querySelector('body').scrollHeight;
  isMobile = ( windowWidth <= mobileBreakpoint );

}); // end window resize functions

/*
 * Functions
 ***************************/
 function get_toc_section_height(el) {
   const col = $('.wp-block-cmyk-toc__item a');
   const len = col.length;
   const index = col.index(el);

   if ( index == len - 1 ) {
     return '100%';
   }

   const next = col.eq(index + 1);

   let firstEl = $($(el).attr('href'));
   if ( firstEl.prev().hasClass('wp-block-image') ) {
     firstEl = firstEl.prev();
   }
   let nextEl = $($(next).attr('href'));
   if ( nextEl.prev().hasClass('wp-block-image') ) {
     nextEl = nextEl.prev();
   }

   return nextEl.offset().top - firstEl.offset().top;
 }
